.header-style_header-wrapper_2809 { 
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 0 1rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    box-sizing: border-box;
 } 
.header-style_image-cover_4137 { 
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
 } 
.header-style_image-square_2339 { 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    width: 30%;
    padding-bottom: 30%;
 } 
.header-style_image-square-placeholder_3562 { 
    border-radius: 25px;
    width: 30%;
    padding-bottom: 30%;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
 } 
.header-style_image-square-placeholder-name_0496 { 
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    padding: 0 5px;
 } 