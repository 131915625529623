.description-view-style_content_7428 { 
    display: flex;
    flex: 1;
 } 
.description-view-style_action-buttons-wrapper-base_1488 { 
    height: 100%;
    margin-top: 10px;
 } 
.description-view-style_action-buttons-wrapper-mobile_9491 { 
    margin-top: 6px;
    height: 100%;
    justify-content: flex-start;

    & > div {
        margin-bottom: 6px !important;
    }

    .loading-button-style_text_0409 {
        padding-left: 4px;
    }
 } 