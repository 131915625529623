@keyframes category-title-style_animation_5230 { 
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  40% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
 }
.category-title-style_category-title_9065 { 
    margin-top: 180px;
    width: 98vw;
    margin-right: auto;
    margin-left: auto;
    align-self: flex-start;
    align-items: baseline;
    font-size: 2.5rem;
    text-transform: capitalize;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    opacity: 0;
    display: flex;

    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-name: category-title-style_animation_5230;
    animation-duration: 450ms;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 80px;
        font-size: 1.3rem;
        margin-left: 0.5rem;
    
    }

 } 