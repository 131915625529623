.neutral-button_neutral-button_3695 { 
    color: var(--v_y7rh7wsg);
    background-color: rgba(255, 255, 255, 0.15);

    &:focus,
    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }

    &:active {
        background-color: rgba(255, 255, 255, 0.5);
    }
 } 