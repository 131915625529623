.lang-selection-style_lang-selection-wrapper-base_4711 { 
    display: flex;
    flex-direction: row;
    overflow: hidden;

    & > :not(:last-child) {
        margin-right: 10px;
    }
 } 
.lang-selection-style_lang-selection-wrapper-tv_4193 { 
    & > :not(:last-child) {
        margin-right: 20px;
    }
 } 