.message-box-style_icon_1332 { 
    margin: 0 auto 1rem auto;
    width: 2.5rem;
    height: 2.5rem;

    svg {
        fill: white;
    }
 } 
.message-box-style_title_2777 { 
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
 } 
.message-box-style_subtitle_0629 { 
    font-size: 0.75rem;
    margin-top: 1rem;
 } 
.message-box-style_message_3173 { 
    line-height: 1.5rem;
 } 
.message-box-style_text_1717 { 
    font-size: 1rem;
 } 
.message-box-style_message-wrapper_2926 { 
    text-align: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-box-style_text_1717 {
        align-self: auto;
        text-align: center;
    }
 } 
.message-box-style_box-title-wrapper_2182 { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
 } 
.message-box-style_icon-title_4217 { 
    width: 1.25rem;
    display: inline-block;
    margin-right: 4px;

    svg {
        fill: white;
    }
 } 
.message-box-style_tick_6330 { 
    margin-left: 0.5rem;
    background-color: white;
    color: var(--v_5t5pc7sz);
    padding: 2px 5px;
    border-radius: 25px;
    font-size: 0.75rem;
 } 
.message-box-style_button-wrapper_2105 { 
    margin-top: 1.5rem;
 } 