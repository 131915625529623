.profile-lock-style_wrapper_0006 { 
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    
 } 
.profile-lock-style_row_0287 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &:first-of-type {
        margin-right: 2.5rem;
    }

    & > :first-child {
        margin-right: 8px;

        
    }
 } 
.profile-lock-style_text_9106 { 
    font-size: 1rem;
 } 
.profile-lock-style_lock-checkbox_2634 { 
    outline: none;

    &:focus {
        .checkbox-style_switch_7656 {
            box-shadow: 0 0 0 var(--v_kmf2gfyr) var(--v_mz87kcg5);
        }
    }
 } 
.profile-lock-style_pin-code-wrapper_1243 { 
    position: relative;
    width: 148px;
    height: 35px;

    

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 135px;
        height: 35px;
    
    }

 } 