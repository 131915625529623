.content-rating-selector-style_wrapper_1807 { 
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    height: 26px;
    padding-top: 4px;

    
 } 
.content-rating-selector-style_item_0003 { 
    position: relative;
    font-size: 1rem;
    z-index: 3;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
    box-sizing: border-box;
    border: 2px solid transparent;
    transition: border-color 200ms ease-out;

    &:focus,
    &:hover {
        border-color: var(--v_uicxor0d);
    }

    width: 26px;
    height: 26px;
    
 } 
.content-rating-selector-style_text_0725 { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 } 
.content-rating-selector-style_cursor_1490 { 
    width: 26px;
    height: 26px;
    position: relative;
    z-index: 2;

    
    position: absolute;
    background-color: var(--v_uigfmdf9);
    border-radius: 50%;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease-out;
 } 
.content-rating-selector-style_backgrounds-wrapper_1900 { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
 } 
.content-rating-selector-style_item-background_6053 { 
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
 } 