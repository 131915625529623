@keyframes mega-progress-bar-style_animation_8560 { 
  from {
    transform: translateY(70px);
  }
  to {
    transform: translateY(0);
  }
 }
@keyframes mega-progress-bar-style_animation-tv_0581 { 
  from {
    transform: translateY(116px);
  }
  to {
    transform: translateY(0);
  }
 }
.mega-progress-bar-style_slide-up_0074 { 
    width: 100%;
    animation: mega-progress-bar-style_animation_8560 0.6s linear;
    
 } 