.wizard-box-wrapper_wizard-box-wrapper-base_5598 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    max-width: calc(100vw - 20px);
    min-width: 450px;
 } 
.wizard-box-wrapper_wizard-box-wrapper-responsive_9706 { 
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 450px;
        padding: 25px;
    
    }

 } 
.wizard-box-wrapper_wizard-box-wrapper-tv_8108 { 
    width: 710px;
    padding: 50px;
 } 