.game-window-minimized-style_wrapper_6928 { 
    width: 80vw;
    margin-right: 1vw;
    display: flex;
    overflow: hidden;
    justify-content: right;
    --gameWindowHeight: 247px; // used in other components

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 98vw;
        margin: 0 auto;
        --gameWindowHeight: 230px;
    
    }


    

  height: var(--gameWindowHeight);
 } 
.game-window-minimized-style_cover_0371 { 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: var(--gameWindowHeight);
    height: 100%;
 } 