.game-window-style_wrapper_3326 { 
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin-bottom: 3px;
    
    }

 } 
.game-window-style_window-blur-box_0266 { 
    
    width: 90vw;
    max-width: 1280px;

    --gameWindowMaxHeight: 500px;
    --gameWindowHeight: 30vw;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 98%;
        --gameWindowHeight: 85vh;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 98%;
        --gameWindowHeight: 80vh;
    
    }


    
    
    height: var(--gameWindowHeight);
    max-height: var(--gameWindowMaxHeight);

 } 
.game-window-style_window-blur-box-fallback_3989 { 
    
    width: 90vw;
    max-width: 1280px;

    --gameWindowMaxHeight: 500px;
    --gameWindowHeight: 30vw;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 98%;
        --gameWindowHeight: 85vh;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 98%;
        --gameWindowHeight: 80vh;
    
    }


    
    
    height: var(--gameWindowHeight);
    max-height: var(--gameWindowMaxHeight);

 } 