.action-buttons-style_wrapper_3934 { 
    display: flex;
    flex-direction: var(--v_txejgg8r);
    align-items: center;
    height: var(--v_1ydu82y3);
    pointer-events: var(--v_xxayzm3a);

    & > div:not(:last-of-type) {
        margin-right: var(--v_3ovqowp2);

        margin-bottom: var(--v_g7akiteh);
    }
 } 
.action-buttons-style_tick_0040 { 
    margin-left: 0.5rem;
    background-color: white;
    color: var(--v_o9zuozc6);
    padding: 2px 5px;
    border-radius: 25px;
    font-size: 0.75rem;
 } 