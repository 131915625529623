.settings-style_wrapper_4974 { 
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    min-width: 225px;
    box-sizing: border-box;
    padding: 20px 30px;
    z-index: 1;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        top: 40px;
        padding: 16px 16px;
    
    }


    
 } 
.settings-style_settings-wrapper_9143 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-bottom: 1rem;
    
    }

 } 
.settings-style_settings-icon_8627 { 
    fill: white;
    width: 20px;
    height: 20px;
    
 } 
.settings-style_settings-text_7570 { 
    font-size: 1.4rem;
    font-weight: bold;
    padding-left: 4px;
    margin: 0;
    
    @media (max-width: 670px) and (orientation: landscape) {
        
        font-size: 1.2rem;
    
    }


    
 } 
.settings-style_buttons_5087 { 
    div:not(:last-of-type) {
        margin-bottom: 12px;
    }
 } 
.settings-style_lang-wrapper_7692 { 
    text-overflow: ellipsis;
    padding: 3px;
    justify-content: center;
    display: flex;

    
 } 
.settings-style_language_8518 { 
    font-size: 1rem;
    margin-left: 0.5rem;
    line-height: 32px;
 } 