.download-ios-style_ios-instructions_7162 { 
    width: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--v_xcyb63yd);
    font-size: 0.85rem;
    background-color: var(--v_beretkny);
    color: var(--v_54udtd0d);
 } 