@keyframes helper-style_fade-in_8307 { 
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
 }
@keyframes helper-style_fade-out_6117 { 
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
 }
.helper-style_wrapper_5497 { 
    position: absolute;
    z-index: 999;
    pointer-events: none;
    // variables are used to combine displacement relative to self and animation
    --tx: var(--v_bhzyab4b);
    // default to top if not specified
    --ty: var(--v_1jnqvdf1);
    transform: translate3d(var(--tx), var(--ty), 0);
    /* transform: translate3d(0, 0, 0); */

    &.animateEnter {
        animation: helper-style_fade-in_8307 300ms ease-in;
    }
    &.animateExit {
        animation: helper-style_fade-out_6117 300ms ease-out;
    }
 } 
.helper-style_helper_1441 { 
    position: relative;
    filter: drop-shadow(15px 15px 6px rgba(0, 0, 0, 0.5));
 } 
.helper-style_handle_0702 { 
    height: 30px !important;
    position: absolute;
    fill: var(--v_sr341u9m);

    // default to left if horizontalPlacement is not specified
    
    
    

        
    
    // default to top if verticalPlacement is not specified
    
    

    transform: var(--v_h93be00i);
 } 
.handle_css_cond_9916 { 
            left: 50px;
         }
.handle_css_cond_7265 { 
            right: 50%;
         }
.handle_css_cond_2370 { 
            right: 50px;
         }
.handle_css_cond_8273 { 
            right: 15px;
         }
.handle_css_cond_2731 { 
            top: -25px;
         }
.handle_css_cond_8214 { 
            bottom: -25px;
         }
.helper-style_bubble_7194 { 
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    width: 300px;
    background-color: var(--v_ln0arr7h);
    color: white;
    border-radius: 20px;

    & > div {
        margin: 5px;
    }
 } 
.helper-style_helper-line_8139 { 
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
 } 
.helper-style_icon-info_9103 { 
    height: 1.2rem;
 } 
.helper-style_helper-title_8747 { 
    text-align: left;
    position: relative;
    font-size: 1.2rem;
    & > :not(:first-child) {
        margin-left: 5px;
    }
 } 
.helper-style_helper-title-text_0898 { 
    flex-grow: 1;
 } 
.helper-style_close-button_2225 { 
    width: 1.2rem;
    cursor: pointer;
    outline: none;
    transition: transform 200ms ease-out;
    &:hover,
    &:focus {
        transform: scale(1.2);
        svg {
            fill: white;
        }
    }
 } 
.helper-style_icon-close_3127 { 
    fill: rgba(255, 255, 255, 0.5);
    transition: fill 200ms ease-out;
    pointer-events: auto;
 } 
.helper-style_helper-content_4145 { 
    text-align: justify;
    font-weight: normal;
 } 
.helper-style_helper-bottom_5477 { 
    justify-content: flex-end;
    font-size: 0.8rem;
    & > :not(:first-child) {
        margin-left: 10px;
    }
 } 
.helper-style_next-button_0061 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    outline: none;
    color: var(--v_ybr8e36z);
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    pointer-events: auto;
    transition: transform 200ms ease-out;

    &:hover,
    &:focus {
        transform: scale(1.1);
    }
 } 
.helper-style_icon-next_2848 { 
    margin-left: 5px;
    height: 0.8rem;
    fill: var(--v_zp3pyun1);
 } 