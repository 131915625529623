.wrapper_wrapper-base_1471 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0px;
    box-sizing: border-box;
    // 600 for 1 and 2, 700 for 3, 800 for more
    min-width: var(--v_22y7jgov);
    max-width: 960px;
 } 
.wrapper_wrapper-responsive_0028 { 
    
    @media (max-device-width: 1024px) {
        
        padding: 0.75rem 0px;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 16px 0px;
        width: 90vw;
        height: 100%;
        min-width: auto;
    
    }

 } 
.wrapper_wrapper-tv_4970 { 
    padding: 28px 90px;
 } 