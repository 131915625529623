.primary-button_primary-button_3004 { 
    background-color: var(--v_d7hvsgyg);
    color: var(--v_b20nxl2o);
    font-weight: var(--v_bpdf20gs);
    cursor: var(--v_e1ug9qr7);

    &:focus,
    &:hover {
        background-color: var(--v_lhmmsgrm);
        color: var(--v_a1i1i0ap);
        transform: var(--v_t1w9f21t);
        font-weight: var(--v_787n4gk4);
    }

    &:active {
        background-color: var(--v_9i2rcc1p);
        color: var(--v_t7vk2lgf);
    }
 } 