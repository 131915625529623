.error_error_0228 { 
    color: var(--v_pgzhg4bs);
    font-size: 1rem;

    margin-bottom: 26px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-bottom: 22px;
    
    }


    
 } 