.search-box-style_wrapper_7500 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 10px;
        min-width: 60vw;
    
    }


    
 } 
.search-box-style_input-wrapper_1684 { 
    flex: 1;
    margin-right: 24px;
    position: relative;

    & > input {
        box-sizing: border-box;
        width: 100%;
        height: 55px;

        padding: 0 1.2rem;
        font-size: 1.5rem;
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0.35);

        
    @media (max-width: 926px) and (orientation: landscape) {
        
            height: 40px;
            font-size: 1.2rem;
            border-radius: 15px;
        
    }


        
    }
 } 
.search-box-style_button-wrapper_7914 { 
    & > :first-child {
        padding: 1.4rem 1.5rem;
        border-radius: 50px;

        
    @media (max-width: 926px) and (orientation: landscape) {
        
            padding: 1rem 1.5rem;
        
    }

    }
 } 
.search-box-style_clear_0657 { 
    outline: none;
    opacity: var(--v_v5icnj1g);
    transition: opacity 200ms ease-out;

    // center vertically inside the input field
    position: absolute;
    bottom: 25%;
    right: 20px;

    width: 1.7rem;
    height: 1.7rem;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 1.4rem;
        height: 1.4rem;
    
    }


    path {
        fill: var(--v_491edhqh);
    }
    &:focus,
    &:hover {
        path {
            fill: var(--v_bcz5bh91);
        }
    }
 } 