.nav-button-style_nav-button-base_0379 { 
    width: var(--v_2bi7brg4);
    height: var(--v_wg1qhid6);
    opacity: var(--v_064h1vul);
    cursor: pointer;
    outline: none;
    pointer-events: var(--v_b1w4vk1f);
    transition: background-color 250ms ease-out, opacity 250ms ease-out;

    ;

    
    @media (max-device-width: 1024px) {
        
        width: var(--v_tnw9mw1r);
        height: var(--v_bzhpai29);
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: var(--v_17a4lz2r);
        height: var(--v_ybgyl6tm);
    
    }
;

    svg {
        fill: var(--v_v2d5d6oe);
    }

    &:focus,
    &:hover {
        opacity: 1;

        svg {
            fill: var(--v_1pl45va2) !important;
        }

        fill: var(--v_ip1ainna) !important;
    }

    &:active {
        opacity: 1;

        svg {
            fill: var(--v_1rppvm5m) !important;
        }
    }
 } 