.game-window-swiper-style_wrapper_2289 { 
    position: absolute;
    z-index: var(--v_ssb5c0jw);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
 } 
.game-window-swiper-style_slider-wrapper_3840 { 
    position: relative;
    height: 100%;
    width: 100%;
 } 
.game-window-swiper-style_swiper-slide-wrapper_6611 { 
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    
    }

 } 