.entry-screen-style_wrapper_3401 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 } 
.entry-screen-style_lang-wrapper_9695 { 
    position: absolute;
    right: 2rem;
    top: 2rem;
 } 
.entry-screen-style_lang-overlay_3707 { 
    width: 0;
    height: 0;
    border-top: 200px solid rgba(0, 0, 0, 0.5);
    border-left: 200px solid transparent;
    position: absolute;
    top: 0;
    right: 0;
 } 
.entry-screen-style_icon-wrapper_0602 { 
    display: var(--v_f40mqaln);
    img {
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.5),
            -8px 8px 8px rgba(0, 0, 0, 0.5), -8px -8px 8px rgba(0, 0, 0, 0.5),
            8px -8px 8px rgba(0, 0, 0, 0.5);
    }
 } 
.entry-screen-style_title_3552 { 
    text-align: center;
    font-size: 2rem;
    margin: 4rem 0 4rem 0;
    white-space: pre-line;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1.25rem;
        margin: 1rem 0 1.5rem 0;
    
    }


    
    @media (max-device-width: 1024px) {
        
        font-size: 1.25rem;
        margin: 2rem 0 2.5rem 0;
    
    }

 } 
.entry-screen-style_bottom-wrapper_0294 { 
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    
    @media (max-device-width: 1024px) {
        
        bottom: 0.5rem;
        justify-content: flex-end;

        > div {
            margin-right: 20px;
        }
    
    }

 } 