.daily-schedule-style_daily-schedule-wrapper_5870 { 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* > * {
        margin: 4px;
    } */

    margin-bottom: 4px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1rem;
        margin-bottom: 0.5rem;
    
    }

 } 
.daily-schedule-style_day-wrapper_7902 { 
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
 } 