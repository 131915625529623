.marketing-slide-style_upper-wrapper_3576 { 
    width: 100%;
    height: 100%;
    position: relative;
 } 
.marketing-slide-style_wrapper_3006 { 
    width: 98%;
    max-width: 1125px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    display: flex;
    height: 100%;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 80%;
    
    }


    
 } 