.avatar_avatar_0138 { 
    display: flex;
    box-sizing: border-box;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
    cursor: pointer;
    -webkit-touch-callout: none;
    border: 6px solid transparent;

    ;
 } 