.audio-video-settings-style_wrapper_2395 { 
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
 } 
.audio-video-settings-style_content-wrapper_4213 { 
    width: 100%;
    display: flex;
 } 
.audio-video-settings-style_left-panel_8907 { 
    width: calc((100% / 3));
 } 
.audio-video-settings-style_camera-stream_1141 { 
    border: 1px solid var(--v_r6m8l1wo);
    border-radius: 20px;
    width: 75%;
    margin: 0 auto;
    padding-bottom: 75%;
    overflow: hidden;
    position: relative;
 } 
.audio-video-settings-style_right-panel_8913 { 
    /* width: calc((100% / 3) * 2); */
    width: 100%;
 } 
.audio-video-settings-style_row-wrapper_8190 { 
    display: flex;
    align-items: center;

    > div {
        margin-right: 1rem;
    }
 } 
.audio-video-settings-style_row-title_9166 { 
    width: 100%;
    margin-bottom: 0.5rem;
 } 
.audio-video-settings-style_label_9891 { 
    font-size: 1rem;
 } 
.audio-video-settings-style_slider-wrapper_2784 { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;

    .audio-video-settings-style_label_9891 {
        margin-right: 0.5rem;
    }
 } 
.audio-video-settings-style_checkbox-wrapper_5579 { 
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .audio-video-settings-style_label_9891 {
        margin-left: 0.5rem;
    }
 } 
.audio-video-settings-style_checkbox-label_9552 { 
    margin-left: 1rem;
    width: 25%;
 } 
.audio-video-settings-style_setting-wrapper-base_7218 { 
    width: 100%;
    display: flex;
 } 
.audio-video-settings-style_setting-col_5507 { 
    flex-direction: column;
 } 
.audio-video-settings-style_setting-row_5949 { 
    display: flex;
    flex-direction: column;

    > label {
        display: block;
    }

    &:not(:last-of-type) {
        margin-bottom: 1.5rem;

        
    @media (max-width: 926px) and (orientation: landscape) {
        
            margin-bottom: 1rem;
        
    }

    }
 } 
.audio-video-settings-style_inner-row_9718 { 
    display: flex;
    align-items: center;
    justify-content: space-between;
 } 
.audio-video-settings-style_setting-title_4248 { 
    margin-bottom: 0.5rem;
 } 