.header-style_header-wrapper_5100 { 
    width: 100%;
    height: 50%;
    flex-grow: 1;
    border-top-left-radius: 20px; // must match blurbox raidus
    border-top-right-radius: 20px; // must match blurbox raidus
    overflow: hidden;
    position: relative;
 } 
.header-style_wrapper_5950 { 
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    min-height: 100px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
 } 
.header-style_countdown_9166 { 
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: center;

    opacity: var(--v_yf5b49eq);
    transition: opacity 500ms ease-out;

    // smooth font render, use with caution, do not use everywhere
    text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5), 0 0 8px rgba(0, 0, 0, 0.5);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-weight: bold;
 } 
.header-style_countdown-item_0182 { 
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
 } 
.header-style_countdown-value_9437 { 
    font-size: 3.5rem;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1.5rem;
    
    }

 } 
.header-style_countdown-label_3607 { 
    font-size: 1.5rem;
    text-transform: capitalize;
 } 
.header-style_wrapper-slide_6105 { 
    height: 100%;
    width: 100%;
 } 