.offers-swiper-style_wrapper_7449 { 
    position: absolute;
    display: var(--v_46qvlasg);
    justify-content: center;
    width: 100%;
    height: 90vh;
    right: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 85vh;
    
    }

 } 
.offers-swiper-style_single-slide-wrapper_3945 { 
    flex-grow: 1;
    max-width: 62%;
 } 
.offers-swiper-style_nav-button-wrapper-base_4722 { 
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 888;
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.offers-swiper-style_nav-button-left-wrapper_4097 { 
    left: 10vw;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        left: 8vw;
    
    }

 } 
.offers-swiper-style_nav-button-right-wrapper_5571 { 
    right: 10vw;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        right: 8vw;
    
    }

 } 