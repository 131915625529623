.arrow-style_arrow-base_7562 { 
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 40px !important;
    cursor: pointer;
    display: var(--v_a19qqose);
    background-color: var(--v_fj1biuwi);
 } 
.arrow-style_arrow-left_6752 { 
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    position: absolute;
    left: -55px;

    &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid white;
    }
 } 
.arrow-style_arrow-right_0151 { 
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    right: -55px;

    &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;

        border-left: 15px solid white;
    }
 } 