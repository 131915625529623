.language-box-style_message-wrapper_7733 { 
    width: 100%;
 } 
.language-box-style_text_6907 { 
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
 } 
.language-box-style_lang-selection-wrapper_3249 { 
    display: flex;
    align-items: center;
    justify-content: var(--v_da20zw0f);
    flex-wrap: wrap;
    margin-top: -30px;
    min-width: var(--v_xvlk2uvt);

    > div {
        margin-right: var(--v_p6z4401n);
    }
 } 
.language-box-style_lang-wrapper_4395 { 
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 30%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 5px;
    transition: background-color 250ms ease-out;
    position: relative;

    &:focus {
        outline: none;
        background-color: var(--v_5a1blm9v);
    }
 } 
.language-box-style_lang_5542 { 
    flex-grow: 1;
    text-align: center;
 } 