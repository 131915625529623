.logo-nav_logo-nav_2147 { 
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 50px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 30px;
    
    }


    
 } 