.expand-button-style_dot_8229 { 
    background-color: white;
    border-radius: 50%;
    width: 5px;
    height: 5px;
 } 
.expand-button-style_wrapper_9806 { 
    background-color: rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 10px;
    border-radius: 50px;
    cursor: pointer;
    transition: 250ms background-color ease-out;

    &:active,
    &:focus {
        background-color: var(--v_udjelq6c);
        outline: none;
    }

    .expand-button-style_dot_8229 {
        &:not(:last-of-type) {
            margin-right: 10px;
        }
    }
 } 