.nav-buttons-style_wrapper_7904 { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    > div {
        margin-bottom: 1rem;
    }
 } 
.nav-buttons-style_nav-button-mobile-base_9839 { 
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
 } 
.nav-buttons-style_nav-button-mobile-left_7935 { 
    left: 10px;
 } 
.nav-buttons-style_nav-button-mobile-right_0948 { 
    right: 10px;
 } 