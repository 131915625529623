.slider-style_wrapper_5117 { 
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    height: var(--v_zn965y7v);
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    transition: border-color 250ms ease-out;
    min-width: var(--v_3j6zxf7o);
 } 
.slider-style_slider-input_6642 { 
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: var(--v_ukg0cw51);
    opacity: 0.7;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    outline: none;

    /* thumb settings *have* to be set separately for moz and webkit */
    &::-moz-range-thumb {
        background: transparent;
        border-color: transparent;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none; /* necessary for Chrome and Edge */
        height: 0; /* necessary for Safari */
        width: 0;
    }

    /* ContentWrapper color */
    & ~ div {
        color: rgba(0, 0, 0, 0.75);
    }
    &:focus ~ div,
    &:hover ~ div {
        color: white;
    }

    /* Icon color */
    & ~ div svg {
        fill: black;
        transition: none !important;
    }
    &:focus ~ div svg,
    &:hover ~ div svg {
        fill: white;
        transition: none !important;
    }
 } 
.slider-style_slider-display_0817 { 
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: var(--v_49zrxi7z);
    height: var(--v_uu5qi9nv);
    border-radius: 50px;
    transition: background-color 250ms ease-out;

    background-color: var(--v_ru2vfa0x);

    input:focus + &,
    input:hover + & {
        background-color: var(--v_rvcvr2qu);
    }

    pointer-events: none;
 } 
.slider-style_content-wrapper_9667 { 
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
 } 
.slider-style_inner-wrapper_8347 { 
    display: flex;
    align-items: center;
    width: 100%;
 } 
.slider-style_icon-wrapper_4355 { 
    height: 25px;
    width: 25px;
    margin: 0 1rem 0 1rem;
 } 
.slider-style_slider-value_7466 { 
    pointer-events: none;
    font-size: 1rem;
 } 