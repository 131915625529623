.login-button-style_wrapper-base_3210 { 
    margin-top: 16px;

    
    @media (max-device-width: 1024px) {
        
        margin-top: 0;
    
    }

 } 
.login-button-style_wrapper-tv_4543 { 
    margin-top: 25px;
 } 