.global-pwa-instructions-style_instructions-wrapper_0728 { 
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-size: cover;
    z-index: 1000;
 } 
.global-pwa-instructions-style_instructions-wrapper-landscape_6667 { 
    
    @media (orientation: landscape) {
        
        display: none;
    
    }

 } 
.global-pwa-instructions-style_rotate-your-phone_1043 { 
    box-sizing: border-box;
    background: var(--v_51hpgf2w);
    padding: 40px;
    border-radius: 20px;
    color: var(--v_mm20xm7o);
    font-size: 1.25rem;
    display: flex;
    max-width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 } 
.global-pwa-instructions-style_rotate-text_8320 { 
    text-align: center;
 } 
.global-pwa-instructions-style_svg-wrapper_5753 { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-top: 20px;

    svg {
        display: block;
    }
 } 
.global-pwa-instructions-style_box_9862 { 
    max-width: 350px;
    width: 90%;
    box-sizing: border-box;
    max-height: 80vh;
    padding-top: 28px;
    background: var(--v_ys4q8hle);
    z-index: 1000;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
 } 
.global-pwa-instructions-style_logo-wrapper_3861 { 
    display: flex;
    justify-content: space-around;
    align-items: center;
 } 
.global-pwa-instructions-style_app-icon_0640 { 
    width: 80px;
    height: 80px;
    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 60px;
        height: 60px;
    
    }
;

    border-radius: 15px;
    background: var(--v_f3kta4ti);

    &:not(last-of-type) {
        margin-right: 10px;
    }
 } 
.global-pwa-instructions-style_fake-icon_0068 {  } 
.global-pwa-instructions-style_logo-icon_8736 { 
    background-size: cover;
 } 
.global-pwa-instructions-style_text-wrapper_1335 { 
    padding: 25px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 } 
.global-pwa-instructions-style_install-title_1605 { 
    margin: 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
 } 
.global-pwa-instructions-style_install-text_3820 { 
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.55rem;
    font-weight: normal;
    color: black;
    text-align: center;
 } 