.nav-buttons-style_wrapper_4388 { 
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-self: flex-start;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-left: 10px;
    
    }

    user-select: none;

    div:not(last-of-type) {
        margin-bottom: 16px;
    }
 } 
.nav-buttons-style_icon-close_1244 { 
    fill: rgba(255, 255, 255, 0.3);
 } 