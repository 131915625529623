.step-wrapper-style_content-wrapper_1282 { 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.step-wrapper-style_close-button_5989 { 
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 50%;
 } 
.step-wrapper-style_icon-close_2723 { 
    fill: rgba(255, 255, 255, 0.3);
 } 
.step-wrapper-style_close-wrapper_1380 { 
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5px;
    right: 5px;
 } 