.maintenance-screen-style_wrapper_8886 { 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.maintenance-screen-style_inner-wrapper_2687 { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 95vw;
 } 
.maintenance-screen-style_content_5199 { 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
 } 
.maintenance-screen-style_warning-wrapper_7968 { 
    width: 35px;
    margin-right: 1rem;
 } 
.maintenance-screen-style_text_5584 { 
    color: var(--v_gdsfg1e5);
 } 