.quick-match-view-style_content_3447 { 
    display: flex;
    flex-direction: column;
    flex: 1;
 } 
.quick-match-view-style_action-buttons-wrapper-base_0162 { 
    flex-direction: row;
    align-self: flex-start;
    margin-top: 10px;

    & > * {
        margin-right: 8px;
        margin-top: 12px;
        padding: 0.7rem 1rem;
        flex: 1;
    }

    
    @media (max-device-width: 1024px) {
        
        & > * {
            padding: 0.7rem 0.5rem;
            min-width: 110px;
        }
    
    }


    
 } 
.quick-match-view-style_action-buttons-wrapper-mobile_0900 { 
    margin-top: 8px;
    height: 100%;
 } 