@keyframes icon-spinner_animation_8025 { 
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
 }
.icon-spinner_icon-spinner_0681 { 
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    animation: icon-spinner_animation_8025 1.2s linear infinite;
 } 