.profile-style_swiper-slide-wrapper_0232 { 
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.profile-style_wrapper_7578 { 
    width: 100%;
    /* height: 100%; */
    max-width: 144px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    outline: none;
    user-select: none;
    margin: var(--v_ow07kxll);

    
 } 
.profile-style_profile-avatar_0234 { 
    position: relative;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 120px;
        width: 120px;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 90px;
        height: 90px;
    
    }
;

    

    &:focus {
        border-color: var(--v_z9njqxd2);
    }

    
    @media (hover: hover) {
        
        &:hover {
            border-color: var(--v_fyk5fgb4);
        }
    
    }


    &:active {
        border-color: var(--v_pp1v8zhf);
    }
 } 
.profile-style_profile-avatar-wrapper_8200 { 
    position: relative;
    /* width: 144px;
    height: 144px; */
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 20px;
    }
 } 
.profile-style_icon-locked-wrapper_5788 { 
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 25px;
        height: 25px;
        bottom: 10px;
        right: 10px;

        svg {
            width: 12px;
        }
    
    }
;
 } 
.profile-style_tag_2064 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 4px 14px;
    margin-top: 8px;
    text-align: center;
    max-width: 110%;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        padding: 2px 6px;
    
    }
;

    
 } 
.profile-style_text_4226 { 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 144px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 85px;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-width: 50px;
    
    }
;

    
 } 
.profile-style_icon_8378 { 
    margin-left: 6px;
    width: 15px;

    
 } 