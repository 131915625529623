.message-box-title-style_box-title-wrapper_1848 { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    width: 100%;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-bottom: 1rem;
    
    }

 } 
.message-box-title-style_wrapper_4130 { 
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
 } 
.message-box-title-style_box-title_6313 { 
    font-size: 1.5rem;
    font-weight: bold;
    white-space: pre-line;
 } 
.message-box-title-style_box-title2_4319 {  } 
.message-box-title-style_icon-title_2347 { 
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    margin-right: 0.75rem;
    position: relative;
    height: 1.8rem;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        fill: white;
    }
 } 