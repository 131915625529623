.input-style_input-base_4592 { 
    padding: 0.438rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    position: relative;
    color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    transition: border 250ms ease-out;
    font-size: 1rem;
    outline: none;
    min-width: 0;
    border: 2px solid transparent;

    &:hover {
        box-shadow: 0 0 0 1px var(--v_yax3lwwi); /* emulate the border */
    }

    &:focus {
        outline: none;
        border-color: var(--v_otam81n0);
    }

    &::placeholder {
        color: var(--v_c4vzyu60);
    }
 } 
.input-style_input-tv_9242 { 
    padding: 0.52rem 0.75rem;
 } 