.bottom-buttons-style_wrapper_8872 { 
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    
    @media (max-device-width: 1024px) {
        
        bottom: 0.5rem;
        justify-content: flex-end;
        > div {
            margin-right: 20px;
        }
    
    }
;
 } 