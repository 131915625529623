.description-view-style_content-wrapper_0109 { 
    display: flex;
    flex-direction: column;
    flex: 1;
 } 
.description-view-style_action-buttons-wrapper-base_2232 { 
    margin-top: 10px;

    

    

    // target buttons
  & > div {
        flex: 1;
        margin-top: 12px;
        padding: 0.7rem 1rem;
        justify-content: space-between;
    }

    
    @media (max-device-width: 1024px) {
        
        & > * {
            padding: 0.7rem 0.5rem;
        }
    
    }

 } 
.action_buttons_wrapper_base_css_cond_8017 { 
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 400px;

            & > div {
                margin-right: 0 !important;
                min-width: 48%;
                max-width: 48%;
            }

            & > * {
                flex: 1;
            }
            & > :nth-child(1) {
                order: 1;
            }

            & > :nth-child(2) {
                order: 3;
            }

            & > :nth-child(3) {
                order: 2;
            }

            & > :nth-child(4) {
                order: 4;
            }

            
    @media (max-device-width: 1024px) {
        
                max-width: unset;
            
    }


            
         }
.action_buttons_wrapper_base_css_cond_1720 { 
            flex-direction: row;
            align-self: flex-start;
         }
.description-view-style_action-buttons-wrapper-mobile_3928 { 
    width: 95%;
    height: 98%;
    margin: 8px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    
 } 
.action_buttons_wrapper_mobile_css_cond_8589 { 
            margin-top: 0;
            justify-content: space-around;

            & > div {
                margin-bottom: 0 !important;
            }
         }