.content-style_content-wrapper_1897 { 
    padding: 0rem 2rem 2rem 2rem;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 0rem 1rem 1rem 1rem;
    
    }

 } 
.content-style_title_9803 { 
    font-size: 1.75rem;
    margin: 0.5rem 0;
    font-weight: bold;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1.25rem;
    
    }

 } 
.content-style_description_6993 { 
    font-size: 1.25rem;
    text-align: justify;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-height: 20vh;
        font-size: 1rem;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-height: 200px;
    
    }
;
 } 
.content-style_action-buttons-wrapper_3896 { 
    margin-top: 2rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 1rem;
    
    }

 } 