.multi-player-filter-style_filter_5446 { 
    font-size: 1.5rem;
    padding: 0.2rem 0.625rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 0.8rem;
    
    }


    text-transform: none;
    border-radius: 50px;
    outline: none;
    display: flex;
    flex-direction: row;
    transition: background-color 200ms ease-out;
    &:focus,
    &:active,
    &:hover {
        background-color: var(--v_lzm510jg);
        * {
            color: var(--v_b43j061y);
            fill: var(--v_uwsoooph);
        }
    }
 } 
.multi-player-filter-style_nb-players-badge_5139 { 
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    padding: 0 0.625rem;
    border-radius: 50px;
    background-color: white;
    color: black;
 } 
.multi-player-filter-style_icon-player_4953 { 
    height: 1.25rem;
    width: 1.25rem;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 0.7rem;
        width: 0.7rem;
    
    }


    align-self: center;
    path {
        transition: fill 200ms ease-out;
    }
 } 
.multi-player-filter-style_wrapper_0564 { 
    margin-left: 20px;
    display: flex;
    flex-direction: row;
 } 