.mega-loader-wrapper_mega-loader-wrapper-base_7926 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 440px;
    padding: 18px;
    overflow: hidden;

    & > :first-child {
        margin-bottom: 1rem;
    }
 } 
.mega-loader-wrapper_mega-loader-wrapper-tv_2938 { 
    width: 725px;
    padding: 36px;

    & > :first-child {
        margin-bottom: 2rem;
    }
 } 