.multi-choice-view-style_content_9360 { 
    display: flex;
    flex-direction: column;
    flex: 1;
 } 
.multi-choice-view-style_wrapper_8112 { 
    display: flex;
    justify-content: space-between;
 } 
.multi-choice-view-style_game-mode_4648 { 
    width: 40%;
    border-radius: 15px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 45%;
        padding: 0.75rem;
    
    }

 } 
.multi-choice-view-style_quickmatch_9378 {  } 
.multi-choice-view-style_introduction_1148 { 
    margin: 1rem 0;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin: 0.5rem 0;
    
    }

 } 
.multi-choice-view-style_local_6293 {  } 
.multi-choice-view-style_title-wrapper_1242 { 
    display: flex;
    align-items: center;

    svg {
        display: block;
        width: 25px;
        max-height: 35px;
        margin-right: 0.5rem;

        path {
            fill: #fff;
        }
    }
 } 
.multi-choice-view-style_title_4095 { 
    font-weight: bold;
 } 
.multi-choice-view-style_description_8861 { 
    flex-grow: 1;
    margin: 0.5rem 0;
 } 
.multi-choice-view-style_button-wrapper_4715 { 
    display: flex;
    justify-content: center;

    > div {
        display: inline-block;
    }
 } 
.multi-choice-view-style_action-buttons-wrapper-base_7744 { 
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
 } 
.multi-choice-view-style_action-buttons-wrapper-mobile_6677 { 
    margin-top: 8px;
 } 