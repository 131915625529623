.action-buttons-wrapper_action-buttons-wrapper_7433 { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 1rem;
    
    }

 } 