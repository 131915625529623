.profile-list-swiper-style_wrapper_0786 { 
    display: flex;
    align-items: center;
    justify-content: var(--v_6dstwf3t);
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 2rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-bottom: 1rem;
    
    }


    
 } 