.icon-button-style_wrapper_2255 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 } 
.icon-button-style_icon_0645 { 
    width: 20px;
    height: 20px;
    

    svg {
        fill: var(--v_3la04fu7);
    }
 } 
.icon-button-style_text_4494 { 
    padding-left: 6px;
    line-height: 20px;

    
 } 