.lang-icon-style_lang-icon-base_1651 { 
    width: var(--v_3tftm9sa);
    height: var(--v_f5ri9nnw);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    flex-shrink: 0;
    box-sizing: border-box;

    &:focus {
        border: 2px solid var(--v_k8897j8r);
    }
 } 
.lang-icon-style_lang-icon-tv_3040 { 
    width: var(--v_sg6uw5oi);
    height: var(--v_cgbqxbnp);

    &:focus {
        border-width: 4px;
    }
 } 