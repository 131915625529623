.parental-control-scheduler-style_heading_7271 { 
    font-size: 1.5rem;
    user-select: none;
    margin-bottom: 1rem;

    
 } 
.parental-control-scheduler-style_content-wrapper_5752 { 
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    width: 100%;
    box-sizing: border-box;

    > .daily-schedule-style_daily-schedule-wrapper_5870:last-of-type {
        margin-bottom: 1.5rem;
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1rem;
    
    }

 } 
.parental-control-scheduler-style_content-wrapper-spacing_5634 { 
    padding: 1.5rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 0.5rem 10px 0.5rem;
    
    }

 } 
.parental-control-scheduler-style_options-wrapper_7729 { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
 } 
.parental-control-scheduler-style_blur-box_5902 { 
    width: 96%;
    max-width: 1350px;
    overflow: hidden;
    filter: var(--v_l9rjsdwc);

    
    @media (max-device-width: 1024px) {
        
        width: 98%;
        max-width: 85vw;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 98%;
        max-width: 100%;
    
    }

 } 
.parental-control-scheduler-style_overlay_1391 { 
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
 } 