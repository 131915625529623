.hours-range-style_wrapper_6825 { 
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.hours-range-style_hour_9369 { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
 } 
.hours-range-style_text_3166 { 
    font-size: 1.5rem;
    margin: 8px;
    visibility: var(--v_9icllh3y);
 } 
.hours-range-style_time-input_1983 { 
    width: 75px;
    padding: 8px;
    font-size: 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 250ms;
    transition-timing-function: ease-out;
    outline: none;
    visibility: var(--v_h14snoiz);
    pointer-events: var(--v_2q679jm8);
    background-color: var(--v_fc7mlcrd);

    &:focus,
    &:hover {
        outline: none;
        background-color: var(--v_4fapsvfj);
        color: 'white';
    }
 } 