.most-searched-style_wrapper_6713 { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
 } 
.most-searched-style_most-searched-cues-wrapper_9575 { 
    width: 100%; //has to be 100% otherwise not detected spatialNav
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    
 } 
.most-searched-style_cues-label_7663 { 
    margin-right: 40px;
    
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin-right: 20px;
    
    }

 } 
.most-searched-style_cues-wrapper_2529 { 
    display: flex;
    flex-direction: row;
    flex-grow: 1;
 } 
.most-searched-style_cue_3925 { 
    user-select: none;
    padding: 2px 10px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.35);
    margin: 4px;
    outline: none;
    cursor: pointer;

    
    &:focus, &:hover {
        background-color: var(--v_92uqrl04);
    }
 } 
.most-searched-style_suggested-games-wrapper_0493 { 
    width: 100%;
    margin-top: 15px;
 } 
.most-searched-style_suggested-games_7109 { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 36px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 8px;
    
    }


    > div:not(:last-of-type) {
        margin-right: 20px;
    }
 } 
.most-searched-style_game-thumbnail-wrapper_5178 { 
    width: 100%;

    &:focus {
        outline: none;
    }
 } 
.most-searched-style_game-thumbnail_6011 { 
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    width: 100%;
    padding-top: 100%;
    transition: box-shadow 100ms ease-out;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        border-radius: 16px;
    
    }

    &:focus, &:active {
        outline: none;
        box-shadow: inset 0 0 0 4px white;
        
    }

    
    @media (hover: hover) {
        
        &:hover {
            cursor: pointer;
            box-shadow: inset 0 0 0 4px white;
        }
    
    }

 } 
.most-searched-style_game-thumbnail-item_2867 {  } 
.most-searched-style_game-no-thumbnail-item_3015 { 
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    span {
        position: absolute;
        top: 50%;
        display: block;
        font-size: 0.65rem;
    }
 } 