@keyframes global-loader-style_animation_9598 { 
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 }
.global-loader-style_wrapper_5976 { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: global-loader-style_animation_9598 0.2s linear;
 } 
.global-loader-style_spinner_8953 { 
    width: 100px;
    height: 100px;

    
 } 