.content-rating-style_wrapper_7758 { 
    margin-bottom: 0.5rem;
    flex-grow: 1;
 } 
.content-rating-style_sub-wrapper_6123 { 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 } 
.content-rating-style_text_8341 { 
    font-size: 1rem;
 } 