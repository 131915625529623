.scroll-view-style_scroll-bar-thumb_4937 { 
    background-color: white;
    width: 10px;
    min-height: 20px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: inset 0 0 13px 12px rgba(255, 255, 255, 0.65);
    box-sizing: border-box;
    transform: translate3d(0, 4px, 0);
 } 
.scroll-view-style_scroll-view-wrapper_4358 { 
    outline: none;
    position: relative;
    max-height: 100%;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    padding: 1px 1rem 1px 1px; //get scrollView navigable with spatial nav

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        flex: 1;
        padding-right: 8px;
    
    }


    

  
 } 
.scroll_view_wrapper_css_cond_4965 { 
            &:focus {
                outline: none;

                ::-webkit-scrollbar-thumb {
                    box-shadow: inset 0 0 13px 12px var(--v_c11suvvg);
                }

                ::-webkit-scrollbar-track {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
         }
.scroll_view_wrapper_css_cond_1625 { 
            &:focus {
                .scroll-view-style_scroll-bar-thumb_4937 {
                    background-color: var(--v_0bpiy0a6);
                    box-shadow: none;
                }

                .scroll-view-style_scroll-bar-wrapper_0734 {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
         }
.scroll-view-style_scroll-content_8429 { 
    outline: none;
    text-align: justify;
    overflow-y: auto;
    max-height: 100%;
    scrollbar-width: var(--v_cdjpap8q);
    padding-right: inherit;

    &::-webkit-scrollbar {
        display: var(--v_zb2q2isw);
    }
 } 
.scroll-view-style_scroll-bar-wrapper_0734 { 
    width: 16px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    overflow: hidden;
 } 