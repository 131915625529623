.regular-button_regular-button_3698 { 
    background-color: var(--v_sdj1sksy);
    color: var(--v_ckf2c8yv);
    pointer-events: var(--v_9zabiv6c);

    &:focus,
    &:hover {
        background-color: var(--v_6z93kc2r);
        color: var(--v_s5hhxeid);
        transform: var(--v_6jpcba74);
        font-weight: var(--v_pxux5t6t);
    }

    &:active {
        background-color: var(--v_9rtue1kf);
        color: var(--v_ptds0uk4);
    }
 } 