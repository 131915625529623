.forgot-password-style_text_5957 { 
    font-size: 0.75rem;
    cursor: pointer;
    user-select: none;
    outline: none;
    align-self: center;
    color: var(--v_dnlho0yg);
    transition: color 150ms ease-out;

    &:focus,
    &:hover {
        color: var(--v_9odrci61);
    }

    &:active {
        color: var(--v_4vk79q5h);
    }
 } 