.profile-selection-screen-style_blur-box-wrapper_0662 { 
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 98vh;
    
    }


    
    @media (max-device-width: 1024px) {
        
        max-width: 98vw;
        max-height: 98vh;
    
    }


    
    @media (max-width: 1300px) {
        
        max-width: 98vw;
    
    }

 } 
.profile-selection-screen-style_welcome-message-base_4216 { 
    margin-top: 16px;
    margin-bottom: 32px;
    white-space: pre-line;
    text-align: center;
    color: var(--v_425nr9uk);
    font-size: 1.5rem;
 } 
.profile-selection-screen-style_welcome-message-responsive_6382 { 
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 9px;
        margin-bottom: 12px;
        font-size: 1rem;
    
    }


    
    @media (max-device-width: 1024px) {
        
        font-size: 1rem;
        margin-bottom: 0.75rem;
    
    }

 } 
.profile-selection-screen-style_welcome-message-tv_4640 { 
    margin-top: 28px;
    margin-bottom: 54px;
 } 