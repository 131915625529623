.tcbuttons_tcbuttons-base_2494 { 
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    margin-top: 40px;
    & > :first-child {
        margin-right: 28px;
    }
 } 
.tcbuttons_tcbuttons-responsive_1879 { 
    margin-top: 20px;
 } 
.tcbuttons_tcbuttons-tv_7093 { 
    margin-top: 32px;

    & > :first-child {
        margin-right: 60px;
    }
 } 