.support-message-box-style_wrapper_8049 { 
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
 } 
.support-message-box-style_build-version_7525 { 
    line-height: 1.5rem;
    width: 100%;
    font-size: 0.75rem;
    margin-bottom: 0;
 } 
.support-message-box-style_support-link_9889 { 
    color: #ffda9f;
    font-style: italic;
 } 
.support-message-box-style_qr-code-wrapper_6767 { 
    margin: 25px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
 } 
.support-message-box-style_qr-code_0860 { 
    margin-top: 1rem;
 } 