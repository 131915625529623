@keyframes login-inputs-wrapper_animation_5701 { 
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
 }
.login-inputs-wrapper_login-inputs-wrapper_2476 { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 12px;
    min-height: 37px;

    & > :not(:last-child) {
        margin-right: 12px;
    }

    & > :not(:first-child) {
        flex-grow: 1;
    }

    & > * {
        animation: login-inputs-wrapper_animation_5701 0.6s linear;
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 16px;
        margin-bottom: 10px;
    
    }


    
    @media (max-device-width: 1024px) {
        
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    
    }


    
 } 
.login-inputs-wrapper_inputs-wrapper_0270 { 
    display: var(--v_7jar47v2);
    margin: 0;
 } 