.content-style_wrapper_7997 { 
    flex-grow: 1;
    height: 50%;
    padding: 0.5rem 2rem 1rem 2rem;
    display: flex;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 0.25rem 1rem 0.5rem 1rem;
    
    }


    
 } 
.content-style_left_6645 { 
    flex-grow: 1;
    display: flex;
    flex-direction: column;
 } 
.content-style_right_0276 { 
    padding: 0 0 0 2rem;
 } 
.content-style_nav-buttons-wrapper_5604 { 
    padding: 0 1rem;
 } 
.content-style_header-wrapper_5801 { 
    display: flex;
    align-items: center;
    justify-content: space-between;
 } 
.content-style_tags-wrapper_3095 {  } 
.content-style_title_9753 { 
    font-size: 2rem;
    font-weight: bold;

    // smooth font render, use with caution, do not use everywhere
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1.5rem;
    
    }

 } 
.content-style_subtitle_0679 { 
    color: var(--v_0aezvwxy);
    margin-bottom: 0.5rem;

    
 } 
.content-style_description_0026 { 
    flex-grow: 1;
    margin-bottom: 1rem;
    column-count: 2;
    column-gap: 2rem;
    text-align: justify;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        column-count: 1;
        margin-bottom: 0.5rem;
    
    }

 } 