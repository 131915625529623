.logo-small_logo-small-base_9007 { 
    width: 90px;
    height: 74px;
 } 
.logo-small_logo-small-responsive_5236 { 
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 77px;
        height: 64px;
    
    }

 } 
.logo-small_logo-small-tv_6621 { 
    width: 127px;
    height: 107px;
 } 