.add-profile-button_icon-wrapper_8325 { 
    display: flex;
    box-sizing: border-box;
    position: relative;
    background-color: rgba(250, 250, 250, 0.35);
    border-radius: 25px;
    box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0);
    width: 132px;
    height: 132px;
    outline: none;
    cursor: pointer;
    -webkit-touch-callout: none;

    ;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 108px;
        width: 108px;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 78px;
        height: 78px;
    
    }
;

    

    &:focus {
        box-shadow: 0px 0px 0px 6px var(--v_leyfwqqk);
        /* border-color: var(--v_h4bbksie); */
    }

    
    @media (hover: hover) {
        
        &:hover {
            box-shadow: 0px 0px 0px 6px var(--v_d6uoqqy7);
            /* border-color: var(--v_pc6hzvnc); */
        }
    
    }

    &:active {
        box-shadow: 0px 0px 0px 6px var(--v_5a2iiccx);
        /* border-color: var(--v_h899ggcj); */
    }

    svg {
        width: 50%;
        margin: auto;
    }
 } 