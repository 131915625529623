.new-games-notification-style_content-wrapper_1843 { 
    display: flex;
    width: 100%;
    max-width: 680px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 } 
.new-games-notification-style_text_8619 { 
    font-size: 1rem;
    text-align: center;
 } 
.new-games-notification-style_swiper-wrapper_8968 { 
    display: flex;
    align-items: center;
    margin: 25px 0;
    width: 100%;
 } 
.new-games-notification-style_swiper-slide-base_5066 { 
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center center;
    background-size: cover;
    border-radius: 25px;
    width: 150px;
    height: 100%;
    box-sizing: border-box;
    transition: border 200ms ease-out;
    transition: box-shadow 200ms ease-out;

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    &:focus {
        outline: none;
        box-shadow: inset 0px 0px 0px 4px #ffffff;
    }

    &:not(:last-of-type) {
        margin-right: var(--v_mtcdi14g);
    }
 } 
.new-games-notification-style_swiper-slide-wrapper_2081 {  } 
.new-games-notification-style_new-game_8605 {  } 
.new-games-notification-style_new-game-no-thumbnail_0506 { 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.5);

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        font-size: 0.65rem;
    }
 } 
.new-games-notification-style_new-games-wrapper_1803 { 
    justify-content: space-evenly;
 } 