.search_search-box-wrapper_0772 { 
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 60vh;
    justify-content: flex-end;
    width: 800px;
    max-width: 98vw;
    margin-bottom: 30px;
    flex-shrink: 0;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 25vh;
        margin-bottom: -20px;
        width: auto;
        min-width: 45vw;
    
    }


    
 } 