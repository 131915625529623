.time-bar-style_rounded-bar_1534 { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    border: none;
    padding: 0.625rem 0;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 0.1rem 0;
    
    }

 } 
.time-bar-style_time-tick_9092 { 
    padding: 0 4px;
    width: 30px;
    &:first-of-type {
        padding-left: 20px;
    }
    &:last-of-type {
        padding-right: 20px;
    }
 } 
.time-bar-style_hidden-time-tick_6659 { 
    padding: 0 4px;
    opacity: 0;
    width: 30px;
 } 
.time-bar-style_time-tick-split_0289 { 
    border-right: 1px dashed rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 1px;
    opacity: var(--v_j1ulwtzc);
    width: 30px;
 } 
.time-bar-style_time-bar-ticks_3993 { 
    width: 100%;
    font-size: 0.75rem;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 3;
    color: black;
    pointer-events: none;
    transition: color 250ms ease-out, background-color 250ms ease-out;
 } 
.time-bar-style_time-bar-wrapper_7206 { 
    cursor: pointer;
    position: relative;
    outline: none;
    width: calc(85% - 60px);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    transition: background-color ease-out 250ms;
    display: flex;
    max-width: 95%;
    overflow: hidden;
    &:focus,
    &:hover {
        > [data-type='TimeBar'] {
            background-color: var(--v_goerqt8b);
            color: white;
        }

        .time-bar-style_time-bar-ticks_3993 {
            color: white;
        }
    }
 } 
.time-bar-style_time-bar_2174 { 
    justify-content: space-between;
    color: black;
    background-color: var(--v_1t82wqw1);
    transition: color 250ms ease-out, background-color 250ms ease-out;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2;
 } 
.time-bar-style_hour-wrapper_9269 { 
    padding: 0 10px;
 } 
.time-bar-style_max-range_6387 { 
    position: absolute;
    top: 0;
    bottom: 0;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
 } 
.time-bar-style_max-range-start_9157 { 
    left: 20px;
 } 
.time-bar-style_max-range-end_7397 { 
    right: 20px;
 } 