.profile-editor-screen-style_heading_0136 { 
    font-size: 1.5rem;
    user-select: none;
    margin-bottom: 1rem;

    
 } 
.profile-editor-screen-style_wrapper_8830 { 
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    min-width: 436px;
    padding: 16px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 90vw;
    
    }


    
 } 
.profile-editor-screen-style_content_2533 { 
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 26px;
    margin-bottom: 40px;

    

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 28px;
        margin-bottom: 34px;
    
    }


  
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin-top: 16px;
        margin-bottom: 16px;
    
    }

 } 
.profile-editor-screen-style_main-content_6849 { 
    width: 100%;
    display: flex;
    flex-direction: row;
 } 
.profile-editor-screen-style_right-pan_1449 { 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;
 } 
.profile-editor-screen-style_row-layout_4261 { 
    > *:not(:last-child) {
        margin-right: 1rem;
    }
 } 
.profile-editor-screen-style_left-pan_3883 { 
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 144px;
    margin-right: 16px;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 140px;
    
    }

    
 } 
.profile-editor-screen-style_profile-name-input_4137 { 
    min-width: 0;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 0 6px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    
 } 
.profile-editor-screen-style_nickname-suggestions_7587 { 
    color: var(--v_rq0vxpnp);
    font-size: 1rem;
    opacity: var(--v_rq63atrl);
    margin-top: 0.5rem;
    margin-left: 6px;
    transition: opacity 250ms ease-out;
 } 