.checkbox-style_switch_7656 { 
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.4s;
    border-radius: 20px;

    &:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
        box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.25);
    }

    
 } 
.checkbox-style_checkbox_6118 { 
    opacity: 0;
    width: 0;
    height: 0;
 } 
.checkbox-style_wrapper_2652 { 
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    outline: none;

    .checkbox-style_checkbox_6118:checked + .checkbox-style_switch_7656 {
        background-color: var(--v_q03aqca1);
    }

    &:focus {
        .checkbox-style_checkbox_6118 + .checkbox-style_switch_7656 {
            box-shadow: 0 0 0 var(--v_6aa59xtz) var(--v_ka5opxcz);
            background: var(--v_ic3itosd);
        }
    }

    // checked animation
    .checkbox-style_checkbox_6118:checked + .checkbox-style_switch_7656:before {
        transform: translateX(14px);

        
    }

    
 } 