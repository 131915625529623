.pin-code-style_wrapper_8388 { 
    display: flex;
    flex-direction: row;
    align-items: center;

    & > :not(:last-child) {
        margin-right: 0.5rem !important;
    }
 } 
.pin-code-style_digit-input_1919 { 
    background-color: rgba(255, 255, 255, 0.25);
    outline: none;
    color: white;
    border-radius: 10px;
    text-align: center;
    margin: 0 !important;
    transition: border 200ms ease-out;
    border: 2px solid transparent;
    box-sizing: border-box;
    flex: 1;
    min-width: 0;
    height: 100%;
    padding: 0;

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield; // Firefox

    &:focus {
        border-color: var(--v_6uce2cgm);
    }
 } 
.pin-code-style_clear_2443 { 
    flex: 1;
    cursor: pointer;
    outline: none;
    fill: white;
    display: flex;

    &:focus,
    &:hover {
        svg {
            fill: var(--v_v39yk4kq);
        }
    }

    &:active {
        svg {
            fill: var(--v_v4muh9hy);
        }
    }
 } 