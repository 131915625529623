.time-box-style_wrapper_1178 { 
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
 } 
.time-box-style_content_3311 { 
    min-width: 350px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 } 
.time-box-style_title_2096 {  } 
.time-box-style_hours-wrapper_7631 { 
    margin: 1.5rem 0;
 } 