.welcome-texte-style_welcome-text-wrapper_7516 { 
    text-align: center;
    margin-top: 10px;
    line-height: 1.5rem;
    color: var(--v_7q1a0x04);

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin-top: 5px;
    
    }

 } 
.welcome-texte-style_welcome-text_0179 {  } 
.welcome-texte-style_account-text_4421 { 
    font-size: 0.85rem;
    padding-top: 1rem;
 } 