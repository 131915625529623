.marketing-window-style_wrapper_4350 { 
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    justify-content: center;
 } 
.marketing-window-style_swiper-style_0190 { 
    width: '100%';
    height: '100%';
 } 