.error-boundary-style_wrapper_3623 { 
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-size: cover;
 } 
.error-boundary-style_error-wrapper_6538 { 
    background-color: var(--v_z9sfbtx6);
    padding: 50px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;

    div:nth-of-type(2) {
        display: inline-flex;
    }
 } 
.error-boundary-style_error-text-wrapper_1545 { 
    text-align: center;
    color: var(--v_hjh8ihj5);
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 } 
.error-boundary-style_logo-icon_2352 { 
    background-size: cover;
    width: 80px;
    height: 80px;
    margin-bottom: 2rem;
 } 
.error-boundary-style_error-title_2114 { 
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
 } 
.error-boundary-style_error-subtitle_2152 { 
    font-size: 1rem;
    color: var(--v_bl08vmda);
 } 
.error-boundary-style_reload-app-button_7038 { 
    background-color: var(--v_qnz9qhoz);

    &:focus,
    &:hover {
        background-color: var(--v_9n8r15sr);
    }

    &:active {
        background-color: var(--v_7q5ryop8);
    }
 } 