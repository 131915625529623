.select-style_wrapper_0887 { 
    width: 100%;
    overflow: hidden;
    border-radius: 50px;
    border: 2px solid rgba(0, 0, 0, 0);
    transition: border 250ms ease-out;

    &:focus,
    &:hover {
        border: 2px solid var(--v_fgktox5x);
    }
 } 
.select-style_select_4182 { 
    width: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    height: var(--v_2k0zekzl);
    color: var(--v_ld3a4pnm);
    background-color: rgba(255, 255, 255, 0.25);
 } 