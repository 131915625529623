.message-box-wrapper-style_wrapper_1086 { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--v_ys2ke2cg);
 } 
.message-box-wrapper-style_blurred-wallpaper-base_6400 { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: var(--v_7n72vg1i);
 } 
.message-box-wrapper-style_blurred-wallpaper-tv_6312 { 
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
 } 
.message-box-wrapper-style_message-box-base_1546 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    font-size: 1.3rem;
    box-sizing: border-box;
    min-width: 650px;
    max-width: 750px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 90vw;
        max-height: 96vh;
        padding: 1.5rem;
    
    }

 } 
.message-box-wrapper-style_message-box-tv_4384 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    padding: 2rem;
    font-size: 1.3rem;
    max-width: 50vw;
    min-width: 600px;
 } 