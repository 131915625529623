.qr-code-box-style_register-url_5453 { 
    text-decoration: underline;
    color: var(--v_2lpg2m43);
    cursor: pointer;
    /* margin-bottom: 1.5rem; */
 } 
.qr-code-box-style_qr-code_8955 { 
    margin-top: 1rem;
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
 } 
.qr-code-box-style_instructions-wrapper_0762 { 
    margin-top: 2rem;
    text-align: center;
    color: var(--v_2wp8pp8y);
 } 