.logo-large_logo-large-base_5446 { 
    width: 320px;
    height: 270px;
 } 
.logo-large_logo-large-responsive_0837 { 
    
    @media (max-device-width: 1024px) {
        
        width: calc((30vh * 320) / 270);
        height: 30vh;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: calc((25vh * 320) / 270);
        height: 25vh;
    
    }

 } 
.logo-large_logo-large-tv_3245 { 
    width: 162px;
    height: 142px;
 } 