.loading-button-style_wrapper_1868 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 } 
.loading-button-style_spinner_5196 { 
    width: 12px;
    height: 12px;
    border-width: 3px;
    
 } 
.loading-button-style_text_0409 { 
    padding-left: 10px;
 } 