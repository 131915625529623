.games-wrapper-style_games-wrapper_6397 { 
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: var(--v_748c6ztj);
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0 !important;
    }

    

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 100vw;
    
    }

 } 
.games-wrapper-style_leading-game_0407 { 
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0 auto;
    height: 75vh;
    flex-shrink: 0;
    padding-bottom: 50px;
    width: 100%;

    
    @media (max-device-width: 1024px) {
        
        height: 80vh;
        padding-bottom: 20px;
    
    }


    
    @media (max-width: 1300px) {
        
        height: 80vh;
        padding-bottom: 30px;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: var(--v_m3fu57qd);
        padding-bottom: 20px;
    
    }
 

  ;
 } 
.games-wrapper-style_no-games-found_9640 { 
    margin-top: var(--v_4uuchrns);
    font-size: 2rem;
    text-align: center;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1.4rem;
    
    }

 } 