.aside-nav-style_icon_1525 { 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    outline: none;
    border-radius: 50%;
    transition: background-color 250ms ease-out;

    svg {
        height: 30px;
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 34px;
        width: 34px;

        svg {
            height: 20px;
        }
    
    }


    
 } 
.aside-nav-style_wrapper_8572 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    position: relative;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 34px;
    
    }

    

    &:hover > .blur-menu-fallback-style_blur-menu-base-fallback-before_2042 {
        display: none;
    }
 } 
.aside-nav-style_aside-nav-selector_8128 { 
    pointer-events: none;
    border-radius: 50%;
    background-color: var(--v_mc850wiw);
    height: 100%;
    width: var(--v_cx0nbbay);
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 250ms ease-out, box-shadow 250ms ease-out,
        opacity 250ms ease-out;
 } 
.aside-nav-style_settings-icon_2507 { 
    svg#icon_settings path {
        fill: var(--v_1qk5s84j);
    }
 } 
.aside-nav-style_search-icon_7978 { 
    svg path {
        fill: var(--v_t9oiqjnu);
    }
 } 
.aside-nav-style_favorite-icon_5003 { 
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
    svg#icon_heart {
        fill: var(--v_4y1i829d);
        transition: fill 600ms ease-out;

        path {
            stroke-width: 2;
            stroke: var(--v_nxopym5z);
            transition: stroke 600ms ease-out;
        }
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding-left: 4px;
        padding-right: 4px;
    
    }

    animation: game-item-style_fav-change_0303 600ms
 } 