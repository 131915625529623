.highlighs-screen-style_marketing-window-wrapper_8881 { 
    width: 100%;
    height: 100%;
    max-height: 55vh;
    position: absolute;
    bottom: 0;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 100%;
        max-height: 70vh;
    
    }


    
    @media (max-width: 1300px) {
        
        height: 100%;
        max-height: 70vh;
    
    }

 } 