.nav-button_nav-button_2114 { 
    align-self: center;
    flex-shrink: 0;
    outline: none;
    cursor: pointer;
    width: 62px;
    height: 62px;
    margin-top: -16px;
    margin-left: 25px;
    margin-right: 25px;
    pointer-events: var(--v_ockfdd81);
    transition: opacity 150ms ease-out;

    ;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-left: 12px;
        margin-right: 12px;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 45px;
        height: 45px;
    
    }
;

    svg {
        fill: var(--v_pgg1eb5k);
    }

    &:focus,
    &:hover {
        svg {
            fill: var(--v_659dowol);
        }
    }

    &:active {
        svg {
            fill: var(--v_oc3cyigt);
        }
    }
 } 