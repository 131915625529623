.more-view-style_content_7999 { 
    margin-top: 16px;

    & * {
        font-size: 1rem;
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-top: 0;

        & * {
            font-size: 0.8rem;
        }

        img {
            height: 80px;
        }
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        & * {
            font-size: 0.7rem;
            margin: 0;
        }

        img {
            height: 60px;
            margin-right: 5px;
        }
    
    }

 } 
.more-view-style_action-buttons-wrapper_7746 { 
    margin-top: 10px;
    height: 100%;
 } 