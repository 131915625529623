.offers-swiper-slide-style_swiper-slide-wrapper_9830 { 
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    
    }

 } 
.offers-swiper-slide-style_wrapper_7258 { 
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    max-width: 750px;

    // TODO: calc with menu height
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 82vh;
        width: 98%;
        bottom: 0;
        margin-bottom: 10px;
    
    }


    

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        height: 84vh;
        margin-bottom: 3px;
    
    }

 } 