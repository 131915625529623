.build-version-style_build-version_9780 { 
    z-index: 1000;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 20px;
    color: white;
    padding: 6px 10px 5px 10px;
    min-width: 50px;
    font-size: 0.75rem;
 } 