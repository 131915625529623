.hours-input-style_wrapper_2069 { 
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.hours-input-style_hours_3979 { 
    font-size: 2rem;
    margin-left: 1rem;
 } 
.hours-input-style_sign-base_2021 { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 2rem;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    transition: background-color 250ms ease-out, color 250ms ease-out,
        border 250ms ease-out;

    &:hover {
        border: 2px solid var(--v_c6bhcqug);
    }

    &:focus {
        outline: none;
        background-color: var(--v_d8n4veb3);
        border: 2px solid var(--v_kc955zed);
    }
 } 
.hours-input-style_plus_8821 {  } 
.hours-input-style_minus_3911 { 
    margin-right: 10px;
 } 