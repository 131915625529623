.daily-usage-style_switch-wrapper_9650 { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    div:first-child {
        margin-right: 10px;
    }

    div:last-child {
        margin-left: 10px;
    }
 } 
.daily-usage-style_check-box-wrapper_0722 { 
    &:focus {
        outline: none;
        color: var(--v_z9wsbsu2);
        .checkbox-style_switch_7656 {
            box-shadow: 0 0 0 var(--v_82dshb7b) var(--v_cyekg7s5);
        }
    }
 } 