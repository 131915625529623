.daily-time-style_time-wrapper_9225 { 
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 15%;
    margin: 0 1rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin: 0 0.25rem;
    
    }

 } 
.daily-time-style_range-mode_0861 { 
    flex-grow: 1;
    font-size: 0.75rem;
    border-radius: 0 50px 50px 0;
    outline: none;
 } 
.daily-time-style_time-amount_2773 { 
    width: 50%;
 } 
.daily-time-style_time-amount-input_2929 { 
    width: 100%;
    outline: none;
    border-style: none;
    border-right-style: dotted;
    border-radius: 50px 0 0 50px;
    border-color: var(--v_glbxtul5);
    border-width: 4px;
    transition: background-color ease-out 250ms;
    background-color: var(--v_ke6gj44y);
    color: 'white';
    &:focus,
    &:hover {
        background-color: var(--v_iox2m5h3);
    }
 } 