.hospitality-style_wrapper_9297 { 
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;

    video {
    }
 } 